.load.bgcolor {
  background-color: white;
}

.load.bgcolor-a4s {
  background-color: #F4FFFD;

}

.boxalign {
  text-align: center;
}

.boxalign1 {
  margin-left: 10px;
  margin-right: 10px;
}

.boxalign2 {
  text-align: center;
  margin-top: 20px;
}

.boxalignbtn {
  margin-left: 40px;
  margin-right: 40px;
}

.boldstyle-f {
  font-weight: bold;
}

.alignstyle {
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.alignstyle1 {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;

}

.typography.heading-h {
  color: #489C8F;
  font-weight: 600;
  font-size: 25px;
  margin-left: 15px;
  margin-top: 4px;
}

.typography.heading-h1 {
  color: #489C8F;
  font-weight: 500;
  font-size: 20px;
}

.typography.heading-h2 {
  font-size: 17px;
  font-weight: 400;
  font-style: italic;
}

.typography.heading-h3 {
  font-size: 13px;
  display: flex;
  margin-bottom: 10px;
}

.typography.heading-h4 {
  font-weight: 500;
  font-size: 20px;
}

.typography.heading-h5 {
  font-weight: 400;
  font-size: 16px;
}

.link.link-varient {
  color: #489C8F;
  font-size: 12px;
}

.link.link-varient1 {
  color: black;
  font-size: 12px;
  display: flex;
  /* justify-content: flex-end; */
}

.textfiled.style-1 {
  background-color: white;
  border-radius: 5px;
  /* border: 1px solid red; */
  display: flex;
  box-shadow: 0px 2px 2px rgba(92, 91, 91, 0.3);
}

.icon.style-i {
  fill: #DADADA;
  font-size: 20px;
  display: flex;
  padding: 3px;
}

.circleicon.style-i {
  fill: #489C8F;
  font-size: 18px;
  margin-right: 1px;
  padding-right: 1px;
}

.btn.btn-log {
  color: white;
  background-color: #489C8F;
  /* margin-top: 30px;
    margin-bottom: 2px; */
  width: 100%;
  border-radius: 5px;
  border: none;
}

.btn.btn-log:hover {
  color: #489C8F;
  background-color: white;
  font-weight: bold;
}

.btn.btn-disable {
  color: white;
  background-color: #489C8F;
  width: 100%;
  border-radius: 5px;
  border: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.btn.btn-disable:hover {
  color: white;
  background-color: #489C8F;
}

.boxstyle {
  /* background-image: url(../Images/bg.png); */
  background-color: #C0DADE;
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  /* background-color:#F4FFFD ;  */
  /* background-position: center;  */
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media (max-width: 768px) {
    .boxstyle {
      background-image: none;
    }
} */

.loading-dots {
  font-size: 2rem;
  color: #489C8F;
  display: inline-block;
  animation: loading-dots 2.5s infinite;
}

@keyframes loading-dots {

  0%,
  20% {
    color: transparent;
    text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 transparent;
  }

  40% {
    color: #489C8F;
    text-shadow: 0.25em 0 0 #489C8F, 0.5em 0 0 transparent;
  }

  60% {
    text-shadow: 0.25em 0 0 #489C8F, 0.5em 0 0 #489C8F;
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 transparent, 0.5em 0 0 #489C8F;
  }
}

.btn.btn-update {
  color: white;
  background-color: #1F77B4;
  margin-right: 10px;
}

.btn.btn-update:hover {
  color: white;
  background-color: #1F77B4;
}

.btn.btn-delete {
  /* color: #303030;
  background-color: #DADADA; */
  margin-right: 10px;
  color: #1F77B4;
  border: 1px solid #1F77B4;
}

.btn.btn-delete:hover {
  /* color: #DADADA;
  background-color: white; */
  font-weight: bold;
  color: #1F77B4;
  border: 1px solid #1F77B4;
}

.btn.btn-save {
  color: white;
  background-color: #1F77B4;
  margin-right: 10px;
  opacity: 0.6;
  cursor: not-allowed;
  position: relative;
  display: flex;
  align-items: center;
}

.btn-text {
  margin-right: 10px;
  /* Adjust this value as needed */
}

.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn.btn-save:hover {
  color: white;
  background-color: #489C8F;
}

.btn.btn-filter-load {
  color: white;
  background-color: #489C8F;
  opacity: 0.6;
  cursor: not-allowed;
  position: relative;
  display: flex;
  align-items: center;
}

.btn.btn-filter-load:hover {
  color: white;
  background-color: #489C8F;
}

.btn.btn-search {
  color: white;
  background-color: #489C8F;
}

.btn.btn-search:hover {
  color: white;
  background-color: #489C8F;
}

.btn.btn-u {
  color: white;
  background-color: #1F77B4;
  margin-left: 10px;
}

.btn.btn-u:hover {
  color: white;
  background-color: #1F77B4;
}

.text-color {
  color: #489C8F;
}

/* .loader {
    background: #000;
    background: radial-gradient(#222, #000);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
} */

.loader-inner {
  bottom: 0;
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.loader-line-wrap {
  animation: spin 2000ms cubic-bezier(.175, .885, .32, 1.275) infinite;
  box-sizing: border-box;
  height: 50px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
  width: 100px;
}

.loader-line {
  border: 4px solid transparent;
  border-radius: 100%;
  box-sizing: border-box;
  height: 100px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.loader-line-wrap:nth-child(1) {
  animation-delay: -50ms;
}

.loader-line-wrap:nth-child(2) {
  animation-delay: -100ms;
}

.loader-line-wrap:nth-child(3) {
  animation-delay: -150ms;
}

.loader-line-wrap:nth-child(4) {
  animation-delay: -200ms;
}

.loader-line-wrap:nth-child(5) {
  animation-delay: -250ms;
}

.loader-line-wrap:nth-child(1) .loader-line {
  border-color: #489C8F;
  height: 90px;
  width: 90px;
  top: 7px;
}

.loader-line-wrap:nth-child(2) .loader-line {
  border-color: #23776a;
  height: 76px;
  width: 76px;
  top: 14px;
}

.loader-line-wrap:nth-child(3) .loader-line {
  border-color: #24d4ba;
  height: 62px;
  width: 62px;
  top: 21px;
}

.loader-line-wrap:nth-child(4) .loader-line {
  border-color: #10619b;
  height: 48px;
  width: 48px;
  top: 28px;
}

.loader-line-wrap:nth-child(5) .loader-line {
  border-color: #1F77B4;
  height: 34px;
  width: 34px;
  top: 35px;
}

@keyframes spin {

  0%,
  15% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.typography-subheading {
  color: #1F77B4;
  /* text-align: center; */
}

.filter-style {
  color: #1F77B4;
  /* text-align: center; */
}

.icon-size {
  height: 25px;
  width: 25px;
}

.form-box {
  border-radius: 5px;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); */
  padding: 10px;
  /* max-height: 600px;
    overflow-y: scroll; */
}

.form.heading {
  color: #1F77B4;
  padding: 5px;
  font-size: 16px;
  margin-bottom: 10px;
}

.form-button-div {
  display: flex;
  justify-content: right;
  margin-top: 10px;
  margin-right: 10px;
}

.content.end {
  display: flex;
  justify-content: flex-end;
}

.search.box {
  border-radius: 20px;
  max-width: 300px;
  padding: 10px;
}

.typography.chart.heading {
  color: #1F77B4;
  font-weight: bold;
  text-align: center;
}

.typography.chart.heading.small {
  color: #1F77B4;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  padding-top: 5px;
}

.card-outline {
  width: 100%;
  border-radius: 10px;
}

.typography.card.title {
  color: #1F77B4;
  font-weight: bold;
}

.typography.card.value {
  color: black;
  font-size: 25px;
}

.box.space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box.end {
  display: flex;
  justify-content: flex-end;
}

.box.vertical-align{
  display: flex;
  align-items: center;
}

.margin-around {
  margin: 10px;
}

.avatar.color {
  background-color: #F4FFFD;
}

.paper.chart {
  height: 350px;
  padding: 10px;
  border-radius: 10px;
}

.image.common {
  width: 300px;
  height: 300px;
}

.image.nodata {
  width: 200px;
  height: 200px;
}

.scrollToTopButton {
  background-color: #c7e6e0;
  border-radius: 50%;
}

.scrollToTopButton.innericon {
  color: #489C8F;
  font-size: 25px;
}

.scrollToTopButton.box {
  position: fixed;
  bottom: 5vh;
  right: 1vw;
}

.span.text-h1 {
  font-size: 12px;
  font-weight: 400;
  /* color: #303030; */
}

.span.text-h2 {
  font-size: 15px;
  font-weight: bold;
  color: #489C8F;
  margin-top: 3px;
}

.span.text-h3 {
  font-size: 14px;
  font-weight: 500;
  color: #1F77B4;
  /* display: flex; */
  /* flex: 1; */
  /* text-align: center; */
  /* justify-content: center; */
}

.span.text-h3.center {
  font-size: 15px;
  font-weight: bold;
  color: #1F77B4;
  display: flex;
  justify-content: center;
}

.span.text-h4 {
  font-size: 15px;
  font-weight: bold;
  color: #1F77B4;
}

.span.form-text {
  font-size: 14px;
  font-weight: 400;
  /* color: #303030; */
}


.b.text-h1 {
  font-size: 12px;
  font-weight: 500;
  color: #1F77B4;
}

.i.icon-1 {
  font-size: 20px;
  color: #489C8F;

}

.card.img-s {
  height: 20px;
  width: 20px;
}

.card.img-i {
  height: 20px;
  width: 20px;
}

.card.boder-r {
  border-radius: 10px;
  width: 100%;
}

.icon.background:hover {
  background-color: transparent;
}

.autocomplete.search {
  background-color: #c9dbf1;
  border-radius: 40px;
}


/* Calendar.css */

.custom.date-calendar {
  width: 250px;
  /* Change to your desired width */
  height: 200px;
  /* Change to your desired height */
  font-size: 15px;
  font-weight: lighter;
  color: #1F77B4;
  padding: 2px;
  font-family: 'Source Sans 3', sans-serif;
}

.box.align-3 {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 2px 2px 5px rgba(5, 5, 5, 0.2);
  /* Adjust values as needed */
}

.card.bg-c {
  background-color: #F4FFFD;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(5, 5, 5, 0.2);
  /* Adjust values as needed */
}

.card.img-u {
  height: 35px;
  width: 35px;
  display: flex;
  padding: 3px;
  text-align: center;
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.icon.style-l {
  fill: #1F77B4;
  font-size: 25px;
  display: flex;
  padding: 3px;
  text-align: center;
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.card.bg-a {
  background-color: #F6F6F6;
  /* margin-left: 10px;
    margin-right: 10px; */
  border-radius: 0px;
}

.card.bg-b {
  background-color: white;
  /* margin-left: 10px;
    margin-right: 10px; */
  border-radius: 0px;
}

.icon.bg-a {
  fill: #1F77B4;
  font-size: 15px;
  margin-left: 10px;
  padding: 2px;
  margin-top: 10px;
}

.icon.bg-b {
  fill: #489C8F;
  font-size: 15px;
  margin-left: 10px;
  padding: 2px;
  margin-top: 10px;
}

.icon.bg-c {
  fill: #B45C1F;
  font-size: 15px;
  margin-left: 10px;
  padding: 2px;
  margin-top: 10px;
}

.icon.bg-d {
  fill: #9C4855;
  font-size: 15px;
  margin-left: 10px;
  padding: 2px;
  margin-top: 10px;
}

.icon.bg-e {
  fill: #DADADA;
  font-size: 15px;
  margin-left: 10px;
  padding: 2px;
  margin-top: 10px;
}

.typograph.text-2 {
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  margin-left: 10px;
}

.typograph.text-3 {
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  margin-right: 10px;
}

.typograph.text-4 {
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  margin-left: 10px;
  font-weight: bold;
}

.typograph.text-5 {
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  font-weight: bold;
  margin-right: 10px;
}

.card.bg-c {
  background-color: white;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 0px;
  box-shadow: 0px;
  display: flex;
  justify-content: space-between;
}

/* style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} */
.typograph.text-6 {
  font-size: 15px;
  margin-top: 5px;
  margin-left: 10px;
}

.typograph.text-7 {
  font-size: 16px;
  margin-top: 5px;
  margin-right: 10px;
}

.typograph.text-8 {
  font-size: 15px;
  margin-top: 5px;
  font-weight: bold;
  margin-left: 10px;
}

.typograph.text-9 {
  font-size: 15px;
  margin-top: 5px;
  font-weight: bold;
  margin-right: 10px;
}

/* drawernew */

/* chatbots start */
.appbar-bottom.chat {
  top: auto;
  bottom: 0;
  background-color: #F4FFFD;
  height: 20px;
  box-shadow: none;
  /* width:1300px; */
}

.left-modal.chat {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-title.chat {
  background-color: #4db6ac;
  color: white;
}

.reddit-icon.chat {
  font-Size: small;
  margin-bottom: 40px;
}

.message-icon.chat {
  font-size: small;
  margin-bottom: 40px;
}

/* left chat */
.left-chat-container {
  background-color: #f5f5f5;
  width: 1000px;
  height: 600px;
  padding: 16px;
  /* Equivalent to p: 4 in Material-UI's theme */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom-box {
  background-color: #7FD3C5;
  backdrop-filter: blur(10px);
  height: 100%;
  width: 28%;
  border-radius: 20px;
}

.search-field {
  position: absolute;
  border-radius: 20px;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  z-index: 1;
  /* ... other styles */
}

.avatar-name {
  position: absolute;
  top: 100px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* ... other styles */
}

.avatar-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.avatar-dot {
  position: absolute;
  bottom: -2px;
  right: 110px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* You can create classes for different colors if needed */
.green-dot {
  background-color: green;
}

.red-dot {
  background-color: red;
}

.main-container {
  background-color: #E6FFFA;
  backdrop-filter: blur(10px);
  height: 100%;
  width: 70%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

/* Styling for the container inside main container */
.inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Styling for Avatar and Dot container */
.avatar-container {
  display: flex;
  align-items: center;
  position: relative;
}

/* Styling for the dot */
.avatar-dot2 {
  position: absolute;
  top: 60%;
  right: 77px;
  /* Adjust this as needed */
  transform: translateY(50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  /* Default color */
}

.attach-file {
  margin-right: 20px;
  color: white;
}

.rightchat-box {
  display: flex;
  flex-direction: column-reverse;
  padding: 16px;
  background-color: white;
}

.chat-text-field {
  width: 100%;
}

/* chatbots end */

.icon.delete {
  color: #9e1b32;
}

.icon.edit {
  color: #0066b2;
}

.btn.btn-text-style {
  text-transform: none;
}

.btn.btn-events {
  color: black;
  background-color: white;
  border-color: #303030;
  margin-left: 5px;
  border-radius: 20px;
  font-size: 10px;
  white-space: nowrap;
}

.btn.btn-events:hover {
  color: black;
  background-color: white;
  border-color: #303030;

}

.box.events.s {
  height: 50px;
  width: 50px;
}

.typograph.text-E1 {
  font-size: 15px;
  margin-top: 5px;
  margin-left: 20px;
  font-weight: bold;
}

.typograph.text-E2 {
  font-size: 13px;
}

.typograph.text-E3 {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 500;
  margin-left: 20px;
}

.typograph.text-E4 {
  font-size: 10px;
  margin-left: 5px;
}

.card.img-E {
  height: 14px;
  width: 14px;
}

/* Eventsettings  styling */
/* EventModal.css */

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 24px;
  padding: 16px;
  min-width: 300px;
}

.title {
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: bolder;
  color: #1F77B4;
}

/* Event title field */
.eventTitle {
  margin-top: 2px;
}

/* Event Date and time field */
.eventDate {
  margin-top: 2px;
  width: 100%;
}

/* Event Description field */
.eventDescription {
  margin-top: 2px;
}

/* Event color field */
.eventColor {
  margin-top: 2px;
}

/* Event icon field */
.eventIcon {
  margin-top: 2px;
}

/* Button */
.saveButton {
  margin-top: 10px;
}


/* EventSettings.css */

/* Calendar container */
.calendarContainer {
  flex: 1 1 100%;
  margin-left: 15px;
}

/* Box for main content */
.mainBox {
  margin: 20px;
}

/* Event content styling */
.eventContent {
  display: flex;
  align-items: center;
}

/* Event dot */
.eventDot {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

/* Additional styling for icons */
.iconMargin {
  margin-right: 5px;
}


/* Style for the title element in the header */
.fc-header-toolbar h2 {
  color: #1F77B4;
}



/* Specific styles for the 'prev' button */
/* You can further customize each button based on their positions */
.fc-header-toolbar button:nth-child(1) {
  background-color: #489C8F;
  /* Change this to your desired button color */
}

/* Specific styles for the 'next' button */
.fc-header-toolbar button:nth-child(2) {
  background-color: #489C8F;
  /* Change this to your desired button color */
}

/* Specific styles for the 'today' button */
.fc-header-toolbar button:nth-child(3) {
  background-color: #489C8F;
  /* Change this to your desired button color */
}

/* Specific styles for the 'dayGridMonth' button */
.fc-header-toolbar button:nth-child(4) {
  background-color: #489C8F;
  /* Change this to your desired button color */
}

/* Specific styles for the 'timeGridWeek' button */
.fc-header-toolbar button:nth-child(5) {
  background-color: #489C8F;
  /* Change this to your desired button color */
}

/* Specific styles for the 'timeGridDay' button */
.fc-header-toolbar button:nth-child(6) {
  background-color: #489C8F;
  /* Change this to your desired button color */
}

/* Specific styles for the 'listMonth' button */
.fc-header-toolbar button:nth-child(7) {
  background-color: #489C8F;
  /* Change this to your desired button color */
}

/* Change button color on hover for all nth-child buttons */
.fc-header-toolbar button:hover:nth-child(1) {
  background-color: grey;
  /* Change this to your desired color on hover for the first button */
}

.fc-header-toolbar button:hover:nth-child(2) {
  background-color: grey;
  /* Change this to your desired color on hover for the second button */
}

.fc-header-toolbar button:hover:nth-child(3) {
  background-color: grey;
  /* Change this to your desired color on hover for the first button */
}

.fc-header-toolbar button:hover:nth-child(4) {
  background-color: grey;
  /* Change this to your desired color on hover for the second button */
}

.fc-header-toolbar button:hover:nth-child(5) {
  background-color: grey;
  /* Change this to your desired color on hover for the first button */
}

.fc-header-toolbar button:hover:nth-child(6) {
  background-color: grey;
  /* Change this to your desired color on hover for the second button */
}

/* InventoryHome */
.inventory-title {
  text-align: center;
  padding-top: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #1F77B4;
}

/* .category-label {
    position: absolute;
    top: 450px;
    left: 500px;
    color: #1F77B4;
  }
  
  .product-label {
    position: absolute;
    top: 450px;
    left: 630px;
    color: #1F77B4;
  } */

/* styles.css */
.custom-card {
  min-width: 275px;
  display: flex;
  flex-direction: column;
  height: 390px;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2px;
  padding-right: 2px;
}
.card-headerP {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 12px;
  font-weight: 500;
}

.card-headerV {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 12px;
  font-weight: 600;
}
.header-text {
  padding-top: 5px;
  padding-left: 10px;
  color: #1f77b4;
}

.header-icons {
  display: flex;
}

.chevron-icon {
  color: #1f77b4;
  cursor: pointer;
}

.table-container {
  max-height: 365px;
  padding-left: 20px;
  padding-right: 20px;
}

.table-header {
  color: #1f77b4;
}

.table-cell {
  line-height: 1;
}

.third-card {
  height: 320px;
  margin-bottom: 20px;
}

.margin-side {
  margin-left: 20px;
  margin-right: 20px;
}

.card-header {
  font-weight: bold;
  font-size: 14px;
  color: #464545;
}

.card-subtitle {
  font-size: 13px;
  color: #8a8585;
}
.card-subtitleP {
  font-size: 12px;
  font-weight: 400;
  color: #8a8585;
}

.card-subtitle1 {
  font-size: 10px;
  font-weight: 500;
  color: #1F77B4;
  margin:5px;
}
.card-subtitle2 {
  font-size: 10px;
  font-weight: 500;
  color: #FFB05A;
  margin: 5px;
}
.card-subtitle3 {
  font-size: 12px;
  font-weight: 500;
  margin: 5px;
  color: #000000;
}

.paper.card {
  border-radius: 10px;
  width: 100%;
  height: 400px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}
.paper.cardA {
  border-radius: 10px;
  width: 100%;
  height: 400px;
  /* padding: 20px; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.chart-graph.text {
  font-size: 15px;
  font-weight: bold;
  color: #1F77B4;
}

.common-icon {
  color: #1F77B4;
}

.common-icon:hover {
  cursor: pointer;
}

.nodata-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.previous-icon {
  color: #1F77B4;
  cursor: pointer;
}

.previous-icon.disable {
  color: rgb(110, 110, 110);
  cursor: default;
}

.icon.dashboard-header {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.filter-icon {
  height: 17px;
  width: 17px;
}

.menu-icon {
  height: 20px;
  width: 20px;
}

.menu-icon-drawer {
  height: 23px;
  width: 23px;
}

.icon.color-i {
  fill: #1F77B4;
  font-size: 20px;
  display: flex;
  /* padding: 3px; */
}

.box.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}

.box.align {
  display: flex;
  align-items: center;
}

.flex-center {
  flex: 1;
  justify-content: center;
}

.ratio-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  /* border-bottom: 1px solid #E6E6E6; */
}

.ratio.card {
  height: 400px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.ration.span{
  color: #489C8F;
  margin-top: 2px;
}

/* .d{
  color: #1F77B4;
  color: #909090;
  color: #0693A0;
  color: #B45C1F;
  color: #489C8F;
  color: #0086AE;
  color: #74cf6c;
} */

.dashboard-card:hover {
  cursor: pointer;
}

.typography-switch.size{
  font-size: 10px;
  font-weight: 400;
}

.btn.btn-compare{
  border: 1px solid #9A9A9A;
  color: #9A9A9A;
  height: 25px;
}

.btn.btn-compare-clicked{
  border: 1px solid #489C8F;
  color: #489C8F;
  height: 25px;
  box-shadow: 1px 1px 0.5px;
}

.header.header-cell {
  color: #489C8F;
  font-weight: bold;
}
 
.table.header-cell-right {
  text-align: right;
}
/* SalesReturnTabledaywise.css */
.custom-data-grid .MuiDataGrid-colCell,
.custom-data-grid .MuiDataGrid-iconSeparator,
.custom-data-grid .MuiDataGrid-sortIcon,
.custom-data-grid .MuiDataGrid-menuIcon,
.custom-data-grid .MuiDataGrid-columnHeaderCheckbox,
.custom-data-grid .MuiDataGrid-columnHeaderDropZone {
  fill: #489C8F;
}

.custom-data-grid .MuiDataGrid-mainGridContainer {
  border: none;
}

.table.table-text-center{
  text-align: center;
}

.table-border{
  border-right: 1px solid #DADADA;
}

.colored-svg  {
  /* filter: brightness(0) invert(1) sepia(1) hue-rotate(90deg) saturate(20); */
  /* fill: #489C8F; */
  stroke: green;
}

img.colored-svg {   fill: #489C8F !important; }

.button.font-size {
  font-size: 10px;
  font-weight: 400;
}

.div.active-menu{
  position: absolute;
  top: 15%;
  left: -3px;
  width: 5px;
  height: 65%;
  background-color: #489C8F;
  border-radius: 50px;
}

.flip-animation {
  animation: flip 1s ease-in-out infinite alternate; /* Change the duration and timing function as needed */
}

@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.grid.center{
  display: flex;
  align-items: center;
}

.sub-title{
  font-size: 11px;
  color: #b9b7b7;
}

.header.title{
  color: #303030;
  font-size: 15px;
  font-weight: 400;
}

.header.subtitle{
  font-weight: 500;
}

.header.icon{
  color: #489C8F;
  font-size: 22px;
  padding-bottom: 3px;
}

.box-style.onboarding{
  margin: 120px;
  background-color: white;
}

.form-onboarding.title{
  color: #1F77B4;
  padding: 5px;
  font-size: 20px;
  /* margin-bottom: 20px; */
}

.form-onboarding.btn.btn-back{
    color: #1F77B4;
    border: 1px solid #1F77B4;
    margin-right: 10px;
}

.span.asterisk{
  color: red;
  font-size: 15px;
}

/* table style */

.table.tableCell {
  border-right: 1px solid #ddd;
}

.table.tableCellBold {
  font-weight: bold;
}

.table.tableCellColor {
  color: #1F77B4;
}

.table.tableCellNoBorder {
  border-bottom: none;
}

.table.tableRowBackground {
  background-color: #F1F1F1;
}

.table.tableCell-heading {
  color: #489C8F;
  font-weight: bold;
}

.table.tableCell-heading1 {
  color: #1F77B4;
  font-weight: bold;
}

.table.tableCellWidths40 {
  width: 50%;
}

.table.tableCellWidths75 {
  width: 75%;
}

.table.tableCellWidths35 {
  width: 25%;
}

.table.tableCellWidths25 {
  width: 25%;
}

.table.tableCellMargin {
  padding-left: 34px;
}

.table.tableCellPadding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.table.tableCellLight {
 color: rgb(145, 145, 145);
}

.table.tableCellsubTitle{
  padding-right: 5px;
  font-size: 12px;
}

.table.tableCellSubLight{
  font-weight: 300;
}

.tableCell.subText {
  font-style: italic;
  color:  #696868;
  font-weight: 300;
  font-size: 14px;
}

/* data source section style */

.col-rightBorder {
  border-right: 1px solid #DADADA;
}

.col-topBorder{
  border-top: 1px solid #DADADA;
}

.connector-form-label {
  color: black;
  font-size: 14px;
}

.connector-div-label{
  font-size: 12px;
  color: black;
}

.connector-form-label.bold {
  font-weight: 500;
}

.connector-form-label-color {
  color: #489C8F;
}

.connector-card {
  background-color: white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 100px;
}

.connector-card-border {
  border: 1px solid #DADADA;
}

.connector-card-border-selected {
  border: 1px solid #489C8F;
}

.connector-img {
  width: 40px;
  height: 40px;
}

.connector-div{
  background-color: white;
  padding: 30px;
  box-shadow: 0px 2px 2px rgba(92, 91, 91, 0.3);
}

.connectors-col{
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.datasource.datasource-heading{
  font-size: 22px;
  font-weight: 500;
}

.datasource.datasource-subtitle{
  font-size: 16px;
}

.datasource.datasource-button{
  background-color: #489C8F;
  color: white;
  font-weight: 500;
  font-size: 15px;
  text-transform: none;
}

.datasource.datasource-button:hover{
  background-color: #489C8F;
  color: white;
  font-weight: 500;
  font-size: 15px;
  text-transform: none;
}

.datasource.datasource-disable-button{
  background-color: #939393;
  color: white;
  cursor: not-allowed;
  /* font-weight: 500;
  font-size: 15px;
  text-transform: none; */
}

.datasource.datasource-disable-button:hover{
  background-color: #939393;
  color: white;
  cursor: not-allowed;
  /* font-weight: 500;
  font-size: 15px;
  text-transform: none; */
}

.button.datasource-add {
  background-color: #1F77B4;
  border-color: #1F77B4;
  height: 40px;
  font-size: 14px;
}

.data-source.heading {
  font-weight: 600;
}

.data-source.subtitle {
  font-size: 14px;
}

.data-source.div {
  background-color: white;
}

.data-source-bottom-border {
  border-bottom: 1px solid #DADADA;
}

.data-source-span {
  font-size: 12px;
}

.data-source-span-color {
  color: #767676;
}

.data-source-operation {
  color: #1F77B4;
  font-weight: 500;
}

.datasource.datasource-checkbox{
  font-size: 14px;
}

.datasoure-toast{
  width: 250px;
}

.dynamic-form-div {
  width: 50%;
}

.dynamic-twofactor-form-div {
  width: 60%;
}

.dynamic-form-headline {
  font-size: 20px;
}

.dynamic-form-subtitle {
  font-size: 16px;
}

.successpopup-button-div {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.successpopup-button {
  width: 45%;
}

.successpopup-addmore-button {
  color: #489C8F;
  border-color: #489C8F;
  background-color: white;
}

.successpopup-addmore-button:hover{
  color: #489C8F;
  border-color: #489C8F;
  background-color: white;
}

.allow-permission-button{
  color: #7A7A7A;
  border-color: #DADADA;
  background-color: white;
}

.allow-permission-button:hover{
  color: #7A7A7A;
  border-color: #DADADA;
  background-color: white;
}

.common-icon{
  cursor: pointer;
}

.button.save {
  background-color: #489C8F;
  border: none;
  color: white;
}

.button.save:hover {
  background-color: #489C8F;
  color: white;
}

.form-error.text {
  color: red;
  font-size: 13px;
}

.table-shadow {
  border-collapse: separate; /* Prevent shadow from being clipped */
  border-spacing: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 10px; /* Optional: Adds rounded corners */
}

.datasource-table-width{
  width: 80%;
}

/* Center container vertically and horizontally */
.tally-centered-container {
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  /* height: 100vh;  */
}

/* Container with white background */
.tally-content-container {
  background-color: #ffffff; /* White background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  width: 100%;
  display: flex;
  flex-direction: column; /* Column layout */
  justify-content: space-between; /* Space between content and footer */
  height: 500px; /* Fixed height for the container */
}

/* Content area with scrollable overflow if needed */
.tally-content {
  flex: 1; /* Take up remaining space */
  overflow-y: auto; /* Enable scrolling if content overflows */
  margin-bottom: 10px; /* Optional: add spacing between content and footer */
}

/* Footer with buttons */
.tally-footer {
  margin-top: auto; /* Push footer to the bottom */
}

.tally.tally-headline{
  font-size: 18px;
  font-weight: 600;
}

.tally.tally-subtitle{
  font-size: 14px;
}

.icon-cursor{
  cursor: pointer;
}

.tally-span{
  font-weight: 500;
}

.span_underline{
  text-decoration: underline;
  cursor: pointer;
}

.checkbox.checkbox-label{
  display: flex;
  align-items: center;
}

.modal.modal-scroll{
  max-height: 400px;
  overflow: auto;
}

.popup-message {
  position: absolute;
  background-color: #F4FFFD;
  /* color: #721c24; */
  border: 1px solid #489C8F;
  border-radius: 5px;
  padding: 8px 12px;
  margin-top: 4px;
  font-size: 14px;
  z-index: 10;
  box-shadow: 0 0px 2px #489C8F;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-form {
  position: absolute;
  background-color: white;
  /* color: #721c24; */
  border: 1px solid #489C8F;
  border-radius: 5px;
  padding: 10px 12px;
  margin-top: 4px;
  font-size: 14px;
  z-index: 10;
  box-shadow: 0 0px 2px #489C8F;
  width: 260px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-textarea {
  border: 1px solid #DADADA; /* Set your desired border color */
  border-radius: 5px; /* Optional: Add rounded corners */
  padding: 5px; /* Optional: Add padding for better usability */
}

.search-input {
  background-image: url('/public/assets/images/search.png'); /* Path to your image inside the public folder */
  background-position: 10px center; /* Adjust position */
  background-repeat: no-repeat;
  background-size: 16px; /* Adjust icon size */
  padding-left: 35px; /* Add left padding to prevent text overlap */
  color: #888888;
}

.search-input::placeholder {
  color: #888888; /* Change placeholder color */
  font-size: 12px;
}

.nested-list {
  list-style-type: none;
  counter-reset: section;
  padding-left: 0;
}

.nested-list > li {
  counter-increment: section;
}

.nested-list > li::before {
  content: counter(section) ". ";
}

.sub-list {
  list-style-type: none;
  counter-reset: subsection;
  margin-left: 20px;
  padding-left: 0;
}

.sub-list > li {
  counter-increment: subsection;
  margin-top: 5px;
}

.sub-list > li::before {
  content: counter(section) "." counter(subsection) " ";
}
