/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@300;400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&family=Source+Sans+3:wght@300;400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
html,
body,
#root,
.app {
  height: 100%;
  margin: 0;
  /* font-family: 'Inter', sans-serif; */
  /* font-family: 'Source Sans 3', sans-serif; */
  font-family: 'Inter', sans-serif;
  background-color: #F4FFFD;
  font-size: 15px;
  color: black;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c7c6c6;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

